import { useStaticQuery, graphql } from 'gatsby'

export const useRelatedResources = () => {
	const { allWpPost } = useStaticQuery(
		graphql`
			query RecentResources {
				allWpPost(
					limit: 10
					filter: {
						locale: {id: {eq: "en_US"}},
					},
					sort: { fields: [date], order: DESC }
				) {
					edges {
						resource: node {
							id
							uri
							date(formatString: "MMMM DD, YYYY")
							title
							categories {
								nodes {
									uri
									name
								}
							}
							resourceInformation {
								service {
									... on WpService {
										title
										uri
										serviceTypes {
											nodes {
												slug
												name
											}
										}
									}
								}
							}
							resourceTypes {
								nodes {
									slug
									name
								}
							}
							featuredImage {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												aspectRatio: 0.75
												layout: CONSTRAINED
												width: 485
											)
										}
									}
								}
							}
						}
					}
				}
			}
		`
	)
	return allWpPost?.edges
}
