import React, { useRef, useCallback, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import TinySlider from 'tiny-slider-react'
import { useRelatedResources } from '../../../hooks/use-related-resources'
import Link from '../../Link'
import StyledCard from '../../Common/StyledCard'
import IconBtn from '../../Common/StyledButton/IconBtn'
import Button from '../../Button'
import {
	relatedWrapper,
	headingWrapper,
	descriptionWrapper,
	navBtns,
	carouselBtns,
	customEdgePadding,
	viewAllButton,
	ctaButtonGroup,
	relatedCopy,
} from './RelatedContent.module.scss'
import { Row, Col } from 'react-bootstrap'

const RelatedContent = ({
							headingText,
							description,
							resources,
							showButton = true,
							buttonPos = 'bottom',
							...props
						}) => {
	if (typeof window === `undefined`) {
		return null
	}

	const [prevDisabled, setPrevDisabled] = useState(true)
	const [nextDisabled, setNextDisabled] = useState(false)

	const title = headingText ?? 'Related Content'

	let resourcesQuery = useRelatedResources()
	let posts = resources ? [...resources, ...resourcesQuery] : resourcesQuery

	// create uniform data
	posts = posts.map(post => {
		return post.hasOwnProperty('resource') ? post.resource : post
	})

	// we combined the user selected resources and queried resources, lets de-dupe
	const seen = []
	posts = posts.filter(function (item, pos) {
		if (!seen.includes(item.id)) {
			seen.push(item.id)
			return true
		} else {
			return false
		}
	})

	posts = posts.slice(0, 9)

	function getWindowDimensions() {
		const { innerWidth: width } = window
		return {
			width,
		}
	}

	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions().width
	)

	const useDebouncedEffect = (effect, delay, deps) => {
		const callback = useCallback(effect, deps)
		useEffect(() => {
			const handler = setTimeout(() => {
				callback()
			}, delay)

			return () => {
				clearTimeout(handler)
			}
		}, [callback, delay])
	}

	const settings = {
		nav: false,
		mouseDrag: true,
		loop: false,
		gutter: 10,
		autoplay: false,
		prevButton: '.prevBtn',
		nextButton: '.nextBtn',
		fixedWidth: 270,
		items: 1,
		responsive: {
			480: {
				items: 1.2,
			},
			768: {
				items: 2.2,
			},
			992: {
				items: 3.2,
				slideBy: 1,
			},
			1200: {
				items: 4,
				fixedWidth: 366,
			},
		},
	}

	const elementRef = useRef()

	useDebouncedEffect(() => elementRef.current.slider.refresh(), 1000, [
		windowDimensions,
	])

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions().width)
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	const onSliderInit = () => {
		const sliderInfo = elementRef.current.slider.getInfo()
		sliderInfo.nextButton.setAttribute("tabindex", 0)
		sliderInfo.prevButton.setAttribute("tabindex", 0)
		disableButtons()
	}

	const disableButtons = () => {
		const sliderInfo = elementRef.current.slider.getInfo()
		const currIndex = sliderInfo.index + sliderInfo.items
		const totalSlides = sliderInfo.slideCount

		// Disable next button if last slide is reached
		setNextDisabled(currIndex === totalSlides)

		// Disable previous button if first slide is reached
		setPrevDisabled(sliderInfo.index === 0)
	}

	const handleDirection = dir => {
		elementRef.current.slider.goTo(dir)
		disableButtons()
	}

	return (
		<div className={relatedWrapper}>
			<div id="nested_outer">
				<Container>
					<Row className='justify-content-between'>
						<Col xs={10} sm={8}    md="auto"  xl={9} className='align-items-center'>
							<Row className={`d-flex align-items-center ${relatedCopy}`}>
								{title && (
									<Col md={4} lg={5}>
										<div className={headingWrapper}>
											<h2 className="title--xl title--decor title--border--mobile">
												{title}
											</h2>
										</div>
									</Col>
								)}

								{description && (
									<Col md={8} lg={7} xl={5} xxl={6}>
										<div className={descriptionWrapper}>
											<p>{description}</p>
										</div>
									</Col>
								)}
							</Row>
						</Col>

						{/* if carousel arrows should display above slider content on mobile */}
						{buttonPos == 'top' ? (
							<Col xs={2} sm={4} lg={3}>
								<div className={navBtns}>
									<RelatedContentNavButtons handleDirection={handleDirection} />
								</div>
							</Col>
						) : (
							<Col xs={4} lg={3} className="d-none d-xl-block">
								<div className={navBtns}>
									<RelatedContentNavButtons handleDirection={handleDirection} />
								</div>
							</Col>
						)}
					</Row>
				</Container>

				<Container className={customEdgePadding} fluid id="nested_inner">
					<TinySlider settings={settings} ref={elementRef} onInit={onSliderInit}>
						{posts && posts.map((resource, index) => {
							const image = resource?.relatedImage ?? resource?.featuredImage
							return (
								<div key={index}>
									<StyledCard
										iconSize="medium"
										variation="CardD"
										service={resource?.resourceInformation?.service}
										label={resource.resourceTypes?.nodes[0]?.name}
										labelId={resource.resourceTypes?.nodes[0]?.slug}
										labelPosRelative={false}
										href={resource.uri}
										eventDetails={null}
										image={
											image?.node?.localFile?.childImageSharp
												?.gatsbyImageData
										}
										imageSize="medium-tall"
										title={resource.title}
									/>
								</div>
							)
						})
						}
					</TinySlider>
				</Container>

				{/* if carousel arrows should display below slider content on mobile */}
				{buttonPos == 'bottom' ? (
					<Container>
						<div className='d-flex d-xl-none justify-content-between'>
							<RelatedContentNavButtons
								handleDirection={handleDirection}
								prevDisabled={prevDisabled}
								nextDisabled={nextDisabled}
								viewAll={showButton}
							/>
						</div>
					</Container>
				) : null}
			</div>
		</div>
	)
}

const RelatedContentNavButtons = ({
  	handleDirection,
  	prevDisabled,
  	nextDisabled,
  	viewAll,
}) => (
	<>
		{viewAll ? (
			<Button
				theme="transparent"
				buttonText="View all Resources & Events"
				className={`d-md-none ${viewAllButton}`}
				to="/resources/"
			/>
		) : null}

		<div className={carouselBtns}>
			<IconBtn
				variant="IconLeftOutline"
				onClick={() => handleDirection('prev')}
				disabled={prevDisabled}
			/>
			<IconBtn
				variant="IconRightOutline"
				onClick={() => handleDirection('next')}
				disabled={nextDisabled}
			/>
		</div>

		<div className={`d-none d-lg-inline-flex btn-group ${ctaButtonGroup}`}>
			<Link
				to="/resources/"
				className="text-uppercase me-0 btn btn-outline-dark"
			>
				More Resources
			</Link>
			<Link to="/events/" className="text-uppercase btn btn-outline-dark">
				More Events
			</Link>
		</div>
	</>
)

export default RelatedContent
